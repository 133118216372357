import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadDiagnosticsPayload } from '../..';
import { TelematicDoctorState } from '../../../store';
import { InputSelectors } from '../../../store/input';
import { InputActions } from '../../../store/input/input.actions';

@Component({
  selector: 'td-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InputComponent implements OnInit, OnDestroy {
  @Input()
  isLoading: boolean;

  inputForm: FormGroup;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private _store: Store<TelematicDoctorState>,
  ) {
    this.inputForm = this.formBuilder.group({
      eid: [
        undefined,
        Validators.pattern(
          '[A-Za-z0-9]{2}:[A-Za-z0-9]{2}:[A-Za-z0-9]{2}:[A-Za-z0-9]{2}:[A-Za-z0-9]{2}:[A-Za-z0-9]{2}',
        ),
      ],
      equipmentNumber: undefined,
    });

    this._store.select(InputSelectors.getIdentifier).subscribe((identifier) => {
      this.inputForm.patchValue(identifier);
    });
  }

  ngOnInit(): void {
    this.inputForm.controls.eid.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((eid) => {
        this.inputForm.controls.eid.setValue(this.mapEID(eid), {
          emitEvent: false,
        });
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setIdentifier(identifier: LoadDiagnosticsPayload) {
    this._store.dispatch(InputActions.SetIdentifier({ payload: identifier }));
  }

  mapEID(eid: string): string {
    if (
      eid.length === 2 ||
      eid.length === 5 ||
      eid.length === 8 ||
      eid.length === 11 ||
      eid.length === 14
    ) {
      return eid + ':';
    } else {
      return eid;
    }
  }

  trimEID(eid: string): string {
    if (
      eid.length === 3 ||
      eid.length === 6 ||
      eid.length === 9 ||
      eid.length === 12 ||
      eid.length === 15
    ) {
      return eid.slice(0, -1);
    } else {
      return eid;
    }
  }
}

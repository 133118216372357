import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'td-detail-dialog',
  templateUrl: './detail-dialog.component.html',
  standalone: false,
})
export class DetailDialogComponent {
  titles: Array<string>;
  hasData: boolean;
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (typeof data === 'object' && data !== null) {
      this.titles = Object.keys(data);
      this.hasData = true;
    }
  }
}

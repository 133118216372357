import { Component } from '@angular/core';
import { Menu } from '@design-system/feature/app-wrapper-v2';

@Component({
  selector: 'td-root',
  templateUrl: './app.component.html',
  standalone: false,
})
export class AppComponent {
  menu: Menu = {
    title: 'application',
    titleIcon: 'pd-telematic-doctor',
    navigation: [
      {
        title: 'analytics.name',
        icon: 'insert_chart_outlined',
        routerLink: '/analytics',
        queryParamsHandling: 'preserve',
      },
      {
        title: 'debugger.name',
        icon: 'bug_report',
        routerLink: '/debugger',
        queryParamsHandling: 'preserve',
      },
    ],
  };
}
